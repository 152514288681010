.contact-section {
    background: rgb(23, 26, 35);
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.contact-text {
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: rgb(255, 255, 255);
}

.contact-url {
    display: flex;
    gap: 40px;
    align-items: center;
}

.contact-url a{
    text-decoration: none;
    color: #FFF;
    font-size: 22px;
    transition: font-size 0.3s ease;
}

.contact-url a:hover {
    font-size: 32px;
}