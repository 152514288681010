.master-container {
    background: rgb(23, 26, 35);
    padding: 8rem 4rem 10rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out forwards;
}


@keyframes fadeIn {
    from {
        opacity: 0; /* Start with opacity 0 */
    }
    to {
        opacity: 1; /* End with opacity 1 */
    }
}

.master-text-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.master-text-wrap h2 {
    font-size: 60px;
    color: rgb(255, 255, 255);
    font-weight: 700;
    margin: 0;
}

.master-text-wrap h2 span {
    color: rgb(178 120 245);
}

.master-text-wrap p {
    font-size: 40px;
    color: rgb(170, 166, 195);
    font-weight: 600;
    margin: 0;
}

.img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-wrap img {
    width: 16rem;
    height: 16rem;
    border-radius: 50%;
    object-fit: cover;
    animation: animate 5s ease-in-out infinite;
    transition: all 1s ease-in-out;
}

@keyframes animate {
    0% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
    50% {
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }
    1000% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}


.about-container {
    background: rgb(23, 26, 35);
    padding: 4rem;
    justify-content: center;
}

.content-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.about-wrap {
    width: 65%;

}

.about-wrap p {
    color: #aaa6c3;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
}

.resume-wrap {
    width: 25%;
    display: flex;
    justify-content: center;
}

.resume-wrap a {
    display: flex;
    padding: 12px 18px;
    gap: 12px;
    font-size: 14px;
    color: #FFF;
    background: rgb(178 120 245);
    cursor: pointer;
    border-radius: 20px;
    border: none;
    font-weight: 600;
    text-decoration: none;
    transition: background-color 0.8s ease;
}

.resume-wrap a:hover {
    background: rgb(130 78 192);
}