.experience-container {
  background: rgb(23, 26, 35);
  padding: 4rem;
  justify-content: center;
}

.title-container {
  text-align: center;
}

.subtitle {
  font-size: 18px;
  color: rgb(170, 166, 195);
  text-transform: uppercase;
}

.title {
  font-size: 36px;
  color: rgb(255, 255, 255);
  font-weight: 700;
}

.icon {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
