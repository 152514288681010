.project-container {
    background: rgb(23, 26, 35);
    padding: 4rem;
    justify-content: center;
}

.title-container {
    text-align: left;
}

.subtitle {
    font-size: 18px;
    color: rgb(170, 166, 195);
    text-transform: uppercase;
}

.title {
    font-size: 36px;
    color: rgb(255, 255, 255);
    font-weight: 700;
}

.cardWrap {
    width: 960px;
    position: relative;
}

.detail-wrap {
    position: inherit;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.prj-description {
    text-align: right;
    background: rgb(23, 26, 35);
    color: white;
    max-width: 25rem;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 1.5rem 2rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, .25);
    border-radius: 8px;
}

.prj-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
}

.prj-title {
    text-align: right;
    font-size: 1.5rem;
    line-height: 2rem;
    color: rgb(255, 255, 255);
}

.prj-img-wrap {
    position: absolute;
    top: 0;
    width: 58%;
}

.prj-img {
    width: 100%;
    border-radius: 12px;
    opacity: 0.8;
}

.prj-tech-list {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    margin-top: 12px;
}

.prj-tech-item {
    font-size: 1rem;
    line-height: 2rem;
    color: rgb(201, 201, 203);
    font-weight: 600;
}

.prj-urls {
    display: flex;
    gap: 14px;
    justify-content: flex-end;
    margin-top: 12px;
}

.prj-urls a {
    text-decoration: none;
    color: rgb(201, 201, 203);
    font-size: 22px;
    transition: font-size 0.3s ease;
}

.prj-urls a:hover {
    font-size: 26px;
}

@media only screen and (max-width: 600px) {
    .cardWrap {
        width: 75%;
    }

    .detail-wrap {
        width: 100%;
        top: 14px;
    }

    .prj-title {
        font-size: 16px;
    }

    .prj-img-wrap {
        width: 100%;
    }

    .prj-description {
        text-align: center;
        padding: 1rem;
        font-size: 12px;
    }
}
